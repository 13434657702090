:root {
    .swal2-container {
        z-index: 3000;
    }

    font-size: small;
}

.MuiPopover-paper {
    box-shadow: 0px 0px 3px 3px rgba(144, 144, 144, 0.1) !important;
}

button {
    text-transform: unset !important;
}

header {
    background-color: white !important;
}